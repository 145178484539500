import Carousel from 'react-bootstrap/Carousel';

import Preview1 from '../img/preview_1.png';
import Preview2 from '../img/preview_2.png';
import Preview3 from '../img/preview_3.png';

function ImagesPreview() {
    return (
        <Carousel fade>
            <Carousel.Item>
                <img alt='preview' width="100%" src={Preview1}></img>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='preview' width="100%" src={Preview2}></img>
            </Carousel.Item>
            <Carousel.Item>
                <img alt='preview' width="100%" src={Preview3}></img>
            </Carousel.Item>
        </Carousel>
    );
}

export default ImagesPreview;