import { LuInstagram } from "react-icons/lu";
import { FaDiscord } from 'react-icons/fa';

function SocialMediaLink({centered}) {
    return (
        <div className={'social-panels' + (centered ? ' centered-content' : '')}>
            <div>
                <a href='https://www.instagram.com/deathdashgame/'><LuInstagram style={{ "margin-right": "20px" }} className='social-icon' size="40px" /></a>
                <a href='https://discord.gg/mE4MwM6WFG'><FaDiscord className='social-icon' size="40px" /></a>
            </div>
        </div>
    );
}

export default SocialMediaLink;
