function Footer() {
    return (
        <>
            <br />

            <p className="centered-text default-font">&copy; AlexBGames 2024-{new Date().getFullYear()}</p>

            <h6 className="centered-text"><a href="/privacy" style={{"textDecoration": "none"}} className="default-font-bold default-link">Privacy Policy</a></h6>

            <h6 style={{"fontSize": "12px"}} className="centered-text default-font">DEATH DASH®, "The Death Dash Logo" are trademarks of Alexander Buchkov. Android is a trademark of Google Inc.</h6>
        </>
    )
}

export default Footer;
