import { Button } from "react-bootstrap";
import DeathDash from "../img/deathdash.png";

function EarlyAccessPage() {
    return (
        <div style={{ "margin": "20px" }}>
            <h1 className="title">Early Access Request</h1>

            <img src={DeathDash} alt="Death Dash" />

            <h1>Death Dash Early Access Request</h1>
            <h2>Enter your email address to get access to the closed-testing app.</h2>

            {/* Form name should match the hidden input */}
            <form name="earlyaccess-info" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                {/* This hidden input is required for Netlify to process the form */}
                <input type="hidden" name="form-name" value="earlyaccess-info" />
                
                {/* Honeypot field to prevent bots */}
                <p style={{ display: "none" }}>
                    <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                </p>

                <input name="emailadress-earlyaccess" type="email" placeholder="Email Address" required />

                <br /><br />

                <Button variant="outline-primary" type="submit">Submit</Button>
            </form>

            <br />

            <h4>After you submit the form, you should wait some time and then you will be able to access the closed-testing app with this link:</h4>
            <a href="https://play.google.com/store/apps/details?id=com.AlexBGames.deathdash">Google Play link for Android</a>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    );
}

export default EarlyAccessPage;