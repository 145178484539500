import GooglePlayBadge from '../img/GetItOnGooglePlay_Badge.svg';
import ImagesPreview from '../components/ImagesPreview';
import SocialMediaLink from '../components/SocialMediaLink';

function HomePage() {
    return (
        <div>
            <div className="cover-graphic"></div>

            <div className='home-body'>
                <div className='home-divider'>
                    <br /><br />

                    <h1 className='title'>DEATH DASH</h1>

                    <div className='centered-content'>
                        <img alt='googleplay-badge' src={GooglePlayBadge}></img>
                    </div>

                    <br /><br />

                    <h4 className='default-font-bold centered-text'>Death Dash is 8-bit endless runner game made for fun. Run through obstacles, while trying to collect more and more points is your main goal.</h4>

                    <br /><br />

                    <h4 className='default-font centered-text'>Don't touch red.</h4>
                    <h4 className='default-font centered-text'>Don't touch white.</h4>
                    <h4 className='default-font centered-text'>Touch blue.</h4>

                    <hr />

                    <h1 className='centered-text'>Follow us for more news</h1>

                    <SocialMediaLink centered />
                </div>

                <br />

                <ImagesPreview />

                <br /><br /><br />

                <div className='home-divider'>
                    <h1>Features</h1>

                    <ul className='default-font'>
                        <li className='default-font'>Simple, but fun gameplay</li>
                        <li className='default-font'>Free to play</li>
                        <li className='default-font'>Characters store with in-game currency (Diamonds) <strong className='default-font-bold'>(Coming Soon)</strong></li>
                        <li className='default-font'>Energetic Levels with emotion and hardness <strong className='default-font-bold'>(Coming Soon)</strong></li>
                        <li className='default-font'>Power-ups that help you make through levels <strong className='default-font-bold'>(Coming Soon)</strong></li>
                    </ul>

                    <br /><br /><br />
                </div>
            </div>
        </div>
    );
}

export default HomePage;
