import { Button } from "react-bootstrap";
import SocialMediaLink from "../components/SocialMediaLink";

function ContactPage() {
    return (
        <div style={{ "margin": "20px" }}>
            <h1 className="title">Contact Me</h1>

            <h1 style={{"color": "var(--bs-red)"}}>READ ME FIRST!</h1>

            <h5 className="default-font">Is your question in the support page?</h5>
            <strong className="default-font-bold">You should contact support only if your question is not in support page, or if the game is not working as intended.</strong>

            <br /><br />
            
            <a href="/support"><Button variant="outline-primary" className="default-font-bold">Visit Support Page</Button></a>

            <br /><br /><br /><br /><br /><br />

            <h1>Contact Links</h1>
            <a href="mailto:contactalexbgames@gmail.com" className="default-font-bold">contactalexbgames@gmail.com - Email Adress</a>

            <h3>Social Medias</h3>
            <SocialMediaLink />

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    )
}

export default ContactPage;
